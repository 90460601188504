import React from 'react';

const TYPES = {
  SET_USE_CHAMELEON: 'set-use-chameleon',
  SET_USE_CHAMELEON_BASED_ON_EP: 'set-use-chameleon-based-on-ep',
};

const { SET_USE_CHAMELEON, SET_USE_CHAMELEON_BASED_ON_EP } = TYPES;

const PREAP_CHAMELEON_EP_CODE = 27;

export const shouldUseChameleon = (ep) => {
  return parseInt(ep, 10) >= PREAP_CHAMELEON_EP_CODE;
};

const reducer = (state, action) => {
  const { type, payload } = action;
  const { epCode, isPreApSubject, preApChameleonFlag } = payload || {};
  const epCodeNumber = epCode ? parseInt(epCode, 10) : null;

  switch (type) {
    case SET_USE_CHAMELEON:
      return {
        ...state,
        useChameleonApp: isPreApSubject ? preApChameleonFlag : true,
      };
    case SET_USE_CHAMELEON_BASED_ON_EP:
      if (isPreApSubject) {
        return {
          ...state,
          epCodeNumber,
          useChameleonApp: preApChameleonFlag
            ? true
            : shouldUseChameleon(epCodeNumber),
        };
      }
      return state;
    default:
      throw Error('action type is not supported');
  }
};

const initialValue = {
  useChameleonApp: null,
  epCodeNumber: null,
};

const Context = React.createContext([]);

export default Context;
export { TYPES, initialValue, reducer, PREAP_CHAMELEON_EP_CODE };
